document.addEventListener('DOMContentLoaded', () => {
  const termsCheckbox = document.getElementById('terms-checkbox');
  const submitButton = document.getElementById('submit-button');

  if (termsCheckbox && submitButton) {
    termsCheckbox.addEventListener('change', (e) => {
      submitButton.disabled = !e.target.checked;
    });
  }
});
